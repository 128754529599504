import React, { useState, useEffect, useCallback } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, TextField, CircularProgress, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import debounce from 'lodash.debounce';
import { getCodingPatientMedicalRecords } from '../../api';
import Paper from '@mui/material/Paper';

const MedicalRecordSearch = ({ username, token, patientId }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  function getRelevantText(text, searchTerm) {
    if (!searchTerm) return text;
  
    const lines = text.split('\n'); // Split text into lines
    const regex = new RegExp(`(${searchTerm})`, 'i'); // Case-insensitive search
    const relevantLines = new Set(); // Use a Set to avoid duplicates
  
    // Find all matching lines and their surrounding lines
    lines.forEach((line, index) => {
      if (regex.test(line)) {
        const start = Math.max(0, index - 3);
        const end = Math.min(lines.length, index + 4);
        for (let i = start; i < end; i++) {
          relevantLines.add(i); // Add line index to the Set
        }
      }
    });
  
    // Construct the final relevant text
    const finalLines = [];
    let prevIndex = -2; // Track the last included index to add ellipses if gaps exist
    Array.from(relevantLines)
      .sort((a, b) => a - b) // Sort indices to maintain order
      .forEach(index => {
        if (index > prevIndex + 1) {
          finalLines.push('...'); // Add ellipsis for skipped lines
        }
        finalLines.push(
          lines[index].replace(
            regex,
            `<span style="background-color: yellow;">$1</span>` // Highlight searchTerm
          )
        );
        prevIndex = index;
      });
  
    return finalLines.join('\n');
  }

  // Fetch records based on the search term
  const fetchRecords = useCallback(
    debounce(async (term) => {
      if (term.length < 3) return; // Only trigger API call for search terms with 3 or more characters

      setLoading(true);
      setError(null);
      try {
        const data = await getCodingPatientMedicalRecords({ username, token, patientId, searchTerm: term });
        setRecords(data);
      } catch (err) {
        setError('Failed to fetch records. Please try again later.');
        setRecords([]); // Clear previous results on error
      } finally {
        setLoading(false);
      }
    }, 500), // Debounce to reduce API calls
    [username, token, patientId]
  );

  // Trigger the debounced fetchRecords function when searchTerm changes
  useEffect(() => {
    fetchRecords(searchTerm);
  }, [searchTerm, fetchRecords]);

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ backgroundColor: '#A7C7E7' }} // Pastel blue background for summary
      >
        <Typography variant="h5">Medical Record Search</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {/* Search Box */}
        <TextField
          fullWidth
          label="Search Medical Records"
          variant="outlined"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          sx={{ marginBottom: 2 }}
        />

        {/* Loader */}
        {loading && <CircularProgress />}

        {/* Error Message */}
        {!loading && error && (
          <Typography variant="body1" color="error">
            {error}
          </Typography>
        )}

        {/* Search Results */}
        {!loading && records.length > 0 ? (
         
            <Grid container spacing={2}>
              {records.map((record, index) => (
                <Grid item xs={12} key={index}>
                  <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      {record.record_title || "Untitled Record"}
                    </Typography>
                    <Typography 
                      variant="body2" 
                      color="textSecondary" 
                      gutterBottom 
                      sx={{ whiteSpace: 'pre-wrap' }}
                    >
                      {/* Display relevant text with highlighted search term */}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: getRelevantText(
                            record.record_text || "No description available.",
                            searchTerm
                          ),
                        }}
                      />
                    </Typography>
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Grid item>
                        <Typography variant="caption" color="primary">
                          <strong>Source:</strong> {record.source || "Unknown"}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="caption" color="primary">
                          <strong>Date:</strong>{" "}
                          {record.source_date
                            ? new Date(record.source_date).toLocaleDateString()
                            : "No date available"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              ))}
            </Grid>
            ) : (
            !loading &&
            searchTerm.length >= 3 && (
                <Typography variant="body1" color="textSecondary" sx={{ marginTop: 2 }}>
                No records found.
                </Typography>
            )
            )}

      </AccordionDetails>
    </Accordion>
  );
};

export default MedicalRecordSearch;
