import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';
import { sendApolloFeedback } from '../../api';

function Feedback({ open, onClose, patientId, username, token, module }) {
  const [feedbackDescription, setFeedbackDescription] = useState('');

  const handleSubmit = async () => {
    let feedbackData = module;
    feedbackData['feedback_description'] = feedbackDescription;

    await sendApolloFeedback(patientId, { username, token}, feedbackData);
    setFeedbackDescription('');
    onClose();
  };

  const handleCancel = () => {
    setFeedbackDescription('');
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md" // Set maxWidth to 'md' or another value as needed
      fullWidth={true}
    >
      <DialogTitle>{module['title']}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="feedbackDescription"
          label={module['feedback_description']}
          type="text"
          fullWidth
          multiline
          rows={4}
          value={feedbackDescription}
          onChange={(e) => setFeedbackDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Feedback;
