import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Grid,
  Divider
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getCodingPatientDetails } from '../../api';
import useFetch from '../../hooks/useFetch';
import MedicalRecordSearch from './MedicalRecordSearch';

const PatientDetails = ({ username, token, patientId }) => {
  const [params, setParams] = useState({ username, token, patientId });
  const { data, loading, error } = useFetch(getCodingPatientDetails, params);

  useEffect(() => {
    setParams({ username, token, patientId });
  }, [username, token, patientId]);

  if (loading) {
    return <Typography variant="h6" align="center">Loading...</Typography>;
  }

  if (error) {
    return <Typography variant="h6" align="center">Error: {error.message}</Typography>;
  }

  // Destructure data
  const { detail, current_hccs, hcc_gaps } = data || {};

  return (
    <div>
      <Paper sx={{ padding: 2, marginTop: 2 }}>
        {/* Medical Record Search */}
        <MedicalRecordSearch username={username} token={token} patientId={patientId} />
        {/* Accordion for Patient Details */}
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ backgroundColor: '#A7C7E7' }} // Pastel blue background for summary
          >
            <Typography variant="h5">Patient Details (#{detail?.patient_id})</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {detail ? (
              <Grid container spacing={2}>
                {/* First Name */}
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1"><strong>First Name:</strong> {detail.first_name}</Typography>
                </Grid>

                {/* Last Name */}
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1"><strong>Last Name:</strong> {detail.last_name}</Typography>
                </Grid>

                {/* Date of Birth */}
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1"><strong>DOB:</strong> {detail.date_of_birth}</Typography>
                </Grid>

                {/* Gender */}
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1"><strong>Gender:</strong> {detail.gender}</Typography>
                </Grid>

                {/* Visited */}
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1"><strong>Visited:</strong> {detail.visited ? 'Yes' : 'No'}</Typography>
                </Grid>

                {/* Dual Status */}
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1"><strong>Dual Status:</strong> {detail.is_dual}</Typography>
                </Grid>

                {/* Eligibility */}
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1"><strong>Eligibility:</strong> {detail.is_eligible}</Typography>
                </Grid>

                {/* Athena Status */}
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1"><strong>Athena Status:</strong> {detail.athena_status}</Typography>
                </Grid>
              </Grid>
            ) : (
              <Typography variant="h6" align="center">No patient details available</Typography>
            )}
          </AccordionDetails>
        </Accordion>
{/* Accordion for HCC Gaps */}
{hcc_gaps && hcc_gaps.length > 0 && (
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ backgroundColor: '#A7C7E7' }} // Pastel blue background for summary
            >
              <Typography variant="h5">HCC Gaps</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Diagnosis</strong></TableCell>
                      <TableCell><strong>Description</strong></TableCell>
                      <TableCell><strong>HCC</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {hcc_gaps.map((hccItem, index) => (
                      <TableRow key={index}>
                        <TableCell>{hccItem.diagnosis_code}</TableCell>
                        <TableCell>{hccItem.description}</TableCell>
                        <TableCell>
                          {hccItem.hcc_code} ({hccItem.hcc_version})
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        )}
        {/* Accordion for Current HCCs */}
        {current_hccs && current_hccs.length > 0 && (
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ backgroundColor: '#A7C7E7' }} // Pastel blue background for summary
            >
              <Typography variant="h5">Current Athena HCCs</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>HCC Code</strong></TableCell>
                      <TableCell><strong>Diagnoses</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {current_hccs.map((hccItem, index) => (
                      <TableRow key={index}>
                        <TableCell>{hccItem.hcc}</TableCell>
                        <TableCell>
                          {hccItem.diagnoses.split('|').map((diagnosis, idx) => {
                            const [code, date] = diagnosis.split(':');
                            return (
                              <div key={idx}>
                                <Typography variant="body2">
                                  <span>{date}: {code} </span>
                                </Typography>
                                {idx < hccItem.diagnoses.split('|').length - 1 && (
                                  <Divider sx={{ my: 1 }} />
                                )}
                              </div>
                            );
                          })}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        )}

        
      </Paper>
    </div>
  );
};

export default PatientDetails;
